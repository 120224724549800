import React from "react";
// import cover2 from "../images/about/cover-2.jpg";

const Newsletter = props => (
  <div className="cover-container cta-container">
    <div className="cover-content">
      <div className="container">
        <h4 className="title-4 text-center color-white">
          COME JOIN THE SPKL EXPERIENCE
          <br /> FOR BEEF LIKE YOU'VE
          <br /> NEVER TASTED BEFORE
        </h4>
        <div className="newsletter-box">
          <p className="color-white">
            Get all the latest news with our monthly newsletter!
          </p>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Enter your email address"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button">
                GET UPDATES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Newsletter;
