import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
// import cover from "../../images/about/cow-large.png";
// import image1 from "../../images/about/about_image1.jpg";
import Newsletter from "../../components/Newsletter";

class AboutBreed extends React.Component {
  render() {
    return (
      <Layout bodyClass="page-aboutBreed">
        <SEO title="Our Breed" />
        <div className="cover-container">
          <div className="cover-img">
            <Img
              fluid={this.props.data.cover.childImageSharp.fluid}
              className="img-fluid"
              alt="Cover Image"
            />
          </div>
          <div className="cover-content">
            <div>
              <h1 className="hero-title">Our Breed</h1>
              {/*} <h4 className="hero-sub-title">There's a new cow in town</h4> */}
            </div>
          </div>
        </div>
        <div className="content">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h1 className="title-1 color-primary">SPECKLE PARK</h1>
                  <h1 className="title-2 color-white">
                    {" "}
                    IS A REMARKABLE BREED OF CATTLE...
                  </h1>
                </div>
                <div className="col-md-8">
                  <p className="color-white">
                    ...with ancient genes dating all the way back to the 1400's.
                    Originating on the prairies of Saskatchewan in Canada,
                    Speckle Park are renowned for their relaxed and friendly
                    temperament as well as their ability to thrive in even the
                    harshest of conditions both extreme cold and extreme
                    drought.
                  </p>
                  <p className="color-white">
                    It's the resilience of this ancestry that's thought to
                    enable them to thrive as well as contribute to the meat
                    marbling well on both grass and grain thus producing the
                    best, most consistently tender Australian beef year round.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="container">
              <h1 className="title-4 text-center color-white">WAGWHO?</h1>
              <h1 className="title-4 text-center color-white">
                {" "}
                ONCE YOU TRY SPKL, YOU'LL FORGET THE REST
              </h1>
              <hr className="custom-hr" />
              <div className="row pb-4">
                <div className="col-md-6">
                  <Img
                    fluid={this.props.data.aboutImg.childImageSharp.fluid}
                    className="shadow-img img-fluid mb-3 mb-md-0"
                    alt="About SPKL"
                  />
                </div>
                <div className="col-md-6 d-flex align-items-center">
                  <div>
                    <p className="color-white">
                      In fact, whilst every animal has its own unique Speckle
                      hide pattern, there is no variation in quality and
                      flavour. A tender taste requires a tender touch, so we
                      make sure our cattle is cared for in a sustainable and
                      respectful manner, as well as being completely hormone
                      free and grain or grass fed depending on the land.
                    </p>
                    <p className="color-white">
                      And with our focus on delivering a premium paddock to
                      plate experience, all SPKL beef that winds up in a
                      restaurant can be traced right back to the farm on which
                      it was raised.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Newsletter />
        </div>
      </Layout>
    );
  }
}

export default AboutBreed;

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "cow-large.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImg: file(relativePath: { eq: "difference.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
